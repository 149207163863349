import { useCallback, useState, useRef, useEffect, useMemo } from 'react';
import {
  Box,
  FormControlLabel,
  Grid,
  Typography,
  Button as MuiButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useHistory, useParams } from 'react-router-dom';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import Quill from 'quill';

import { StyledInput } from '@components/styledComponents/StyledInput';
import Button from '@components/common/Button/Button';
import {
  SETTINGS_FORM_DEFAULT_VALUE,
  SettingsForm,
  SettingsFormType,
} from '@pages/Manager/CommonForms/SettingsForm';
import FileUpload from './components/FileUpload/FileUpload';
import { ReactFilesFile } from './components/FileUpload/types';
import StyledCheckbox from '@components/common/StyledCheckbox/StyledCheckbox';
import Wysiwyg from '@components/common/Wysiwyg/Wysiwyg';
import EmptyData from '@components/common/EmptyData/EmptyData';
import { Loader } from '@components/common/Loader/Loader';
import { newsAPI } from '@api/news';
import { News, NewsStatus } from '@declarations/news';
import { useSnackbar } from '@hooks/useSnackbar';
import { FileDto, isOfType } from '@declarations/common';
import { ReactComponent as IconTrash } from '@assets/icons/trash.svg';
import { ReactComponent as IconEye } from '@assets/icons/eye.svg';
import { PREVIEW_ID_VALUE, PREVIEW_LS_KEY } from '@constants/news';
import { generateLink } from '@lib/common';
import { ROUTES } from '@constants/routes';
import { useTargetUserCount } from '@hooks/useTargetUserCount';
import { useSelector } from 'react-redux';
import { RootState } from '@redux/store';
import { UI_MESSAGE } from '@declarations/enum/uiMessage';
import { useCheckForAdminStructuredRole } from '@hooks/useCheckForAdminStructuredRole';
import { StructureRole } from '@declarations/enum/structureRole';

type FormData = {
  settings: SettingsFormType;
  isImportant: boolean;
  title: string;
  files: (File | FileDto)[];
  body: string;
} & Partial<{
  images: (ReactFilesFile | FileDto)[];
}>;

const InputFile = styled('input')({
  display: 'none',
});

const PREVIEW_LINK = generateLink(ROUTES.newsItem, { id: PREVIEW_ID_VALUE });

export const NewsForm = () => {
  const editorRef = useRef<Quill | null>(null);
  const filesRef = useRef<any>();
  const history = useHistory();
  const { id } = useParams<{ id: string | undefined }>();
  const { addSnack } = useSnackbar();

  const [formData, setFormData] = useState<FormData>({
    title: '',
    body: '',
    isImportant: false,
    settings: SETTINGS_FORM_DEFAULT_VALUE,
    files: [],
  });
  const [showSettings, setShowSettings] = useState(true);
  const [isEditorContentEmpty, setIsEditorContentEmpty] = useState(true);
  const [isLoadNews, setIsLoadNews] = useState(!!id);
  const [errLoadNews, setErrLoadNews] = useState(false);

  const functionalRoles = useSelector(
    (state: RootState) => state.user.functionalRoles,
  );

  const funcRoleId = useMemo(
    () => functionalRoles.find((item) => item.role === 'NewsCRUD')?.id,
    [functionalRoles],
  );

  const { isRole: isOOGroupsAdmin } = useCheckForAdminStructuredRole(
    StructureRole.OO_GROUP_ADMINISTRATOR,
  );

  const { userCount, isAllUsers, isLoadError } = useTargetUserCount(
    formData.settings,
  );

  const pdfFileName = useMemo(() => {
    const file = formData.files?.find((item) => {
      // TODO-achernyavets, поправить когда починят дублирование файлов
      // Пока есть ошибка с дублированием файлов, приходится делать такие костыли
      if (isOfType<FileDto>(item, 'format')) {
        return item.format === 'pdf';
      }
      return true;
    });
    if (!file) {
      return;
    }
    if (isOfType<File>(file, 'name')) {
      return file?.name;
    }
    return file.originalFileName;
  }, [formData.files]);

  const disableSave =
    isEditorContentEmpty ||
    formData.title.length === 0 ||
    (!!isOOGroupsAdmin &&
      (!formData.settings.region.length || !formData.settings.ooGroup.length));

  const disabledPublishBtn = useMemo(() => {
    if (disableSave) {
      return true;
    }
    if (formData?.settings?.period?.length) {
      return (
        dayjs(formData?.settings?.period[0]).toISOString() >
        dayjs().toISOString()
      );
    }
  }, [formData.settings.period, disableSave]);

  const handlePreview = useCallback(async () => {
    try {
      const bodyContent = editorRef.current?.root.innerHTML || '';
      const newsItem = await mapToNews(formData, NewsStatus.DRAFT, bodyContent);
      // Получаем обновленные данные по файлам и перезаписываем, чтобы повторно не сохранять
      const newFormData = mapNewsToFormData(newsItem);
      setFormData((prev) => ({
        ...prev,
        files: newFormData.files,
        images: newFormData.images,
      }));
      // Идет открытие в новом окне, redux state там пустой, поэтому делаем через LS
      localStorage.setItem(PREVIEW_LS_KEY, JSON.stringify(newsItem));
      const newWindow = window.open(
        PREVIEW_LINK,
        'example',
        'width=1000,height=800',
      );
      if (newWindow) {
        newWindow.onload = function () {
          newWindow.document.body.style.padding = '24px';
        };
      }
    } catch (err) {
      console.error(err);
      addSnack('Не удалось сформировать превью для новости', 'error');
    }
  }, [formData, addSnack]);

  const onSubmit = useCallback(
    async (status: NewsStatus) => {
      try {
        const bodyContent = editorRef.current?.root.innerHTML || '';
        const newsItem = await mapToNews(formData, status, bodyContent);
        if (id) {
          await newsAPI.update(newsItem, id);
        } else {
          await newsAPI.add(newsItem);
        }
        addSnack(id ? 'Новость обновлена' : 'Новость создана', 'success');
        history.goBack();
      } catch (err) {
        console.error(err);
        addSnack(
          id ? 'Ошибка при обновлении новости' : 'Ошибка при создании новости',
          'error',
        );
      }
    },
    [history, formData, addSnack, id],
  );

  const handleChangeFormData = useCallback(
    (newData: { [key: string]: any }) =>
      setFormData((prevState: any) => ({
        ...prevState,
        ...newData,
      })),
    [],
  );

  const handleSave = useCallback(() => onSubmit(NewsStatus.DRAFT), [onSubmit]);

  const handlePublish = useCallback(
    () => onSubmit(NewsStatus.PUBLISHED),
    [onSubmit],
  );

  const handleEditorChange = (html: string, text: string, quill: Quill) => {
    setIsEditorContentEmpty(quill.getLength() <= 1);
  };

  useEffect(() => {
    const loadData = async (newsId: number) => {
      try {
        const newsItem = await newsAPI.get(newsId, true);
        if (newsItem) {
          setFormData(mapNewsToFormData(newsItem));
        }
      } catch (err) {
        setErrLoadNews(true);
        addSnack(UI_MESSAGE.NEWS_NOT_AVAILABLE, 'error');
      } finally {
        setIsLoadNews(false);
      }
    };
    if (id && +id) {
      loadData(+id);
      return;
    }
    if (id && id !== 'add') {
      setIsLoadNews(false);
      setErrLoadNews(true);
      addSnack(UI_MESSAGE.NEWS_NOT_AVAILABLE, 'error');
    }
  }, [addSnack, id]);

  if (isLoadNews) {
    return <Loader />;
  }

  if (errLoadNews) {
    return <EmptyData />;
  }

  return (
    <div>
      <Grid container sx={{ marginBottom: '32px' }}>
        <Grid item xs alignItems="center">
          <Typography variant="h2" sx={{ fontWeight: '500', fontSize: '18px' }}>
            {id ? 'Редактирование' : 'Новая новость'}
          </Typography>
        </Grid>
        <Grid item />
      </Grid>
      <Grid container sx={{ maxWidth: '768px' }}>
        <Grid item xs={3}>
          Заголовок
          <span className="mandatory-field">*</span>
        </Grid>
        <Grid item xs={9}>
          <Box sx={{ mb: 2 }}>
            <StyledInput
              className={'news__search'}
              size={'medium'}
              fullWidth
              value={formData.title}
              onChange={(e) =>
                handleChangeFormData({
                  title: e.target.value,
                })
              }
            />
          </Box>
        </Grid>
        <Grid item xs={3}>
          Изображение
        </Grid>
        <Grid item xs={9}>
          <Box sx={{ mb: 2 }}>
            <FileUpload
              ref={filesRef}
              onChange={(files) => {
                handleChangeFormData({
                  images: files,
                });
              }}
              accepts={['image/png', 'image/jpeg', 'image/jpg']}
              maxFileSize={2}
              maxFilesCount={1}
              value={formData.images}
            />
            {/* TODO: что делать, если файл выбран - непонятно, дизайна нет */}
          </Box>
        </Grid>
        <Grid item xs={3}>
          Текст
          <span className="mandatory-field">*</span>
        </Grid>
        <Grid item xs={9}>
          <Box sx={{ mb: 2 }}>
            <Wysiwyg
              ref={editorRef}
              defaultValue={formData.body}
              options={[
                ['history'],
                ['image', 'video'],
                [
                  'headers',
                  'bold',
                  'italic',
                  'background',
                  'align',
                  'list',
                  'indent',
                  'link',
                  'clean',
                ],
              ]}
              align={{
                isDropDown: true,
              }}
              onChange={handleEditorChange}
            />
          </Box>
        </Grid>
        <Grid item xs={3}>
          Добавить
        </Grid>
        <Grid item xs={9}>
          <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
            <label htmlFor="contained-button-file">
              <InputFile
                accept=".pdf"
                id="contained-button-file"
                type="file"
                onChange={(e) => {
                  handleChangeFormData({
                    files: e.target.files ? Array.from(e.target.files) : [],
                  });
                  e.target.value = '';
                }}
              />
              <MuiButton
                variant="outlined"
                size="small"
                startIcon={<InsertDriveFileOutlinedIcon />}
                component="span"
              >
                {pdfFileName || 'Файл'}
              </MuiButton>
            </label>
            {formData.files.length > 0 && (
              <Box sx={{ ml: 2, display: 'flex' }}>
                <IconTrash
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    handleChangeFormData({
                      files: [],
                    })
                  }
                />
              </Box>
            )}
          </Box>
        </Grid>

        {showSettings ? (
          <>
            <Grid item xs={3}>
              <Typography fontSize="18px" fontWeight="500" color="#2C3038">
                Настройки
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Box sx={{ mb: 2 }}>
                <button
                  className="quiz-form__text-btn"
                  onClick={() => setShowSettings(false)}
                >
                  Свернуть настройки
                </button>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <SettingsForm
                value={formData.settings}
                funcRoleId={funcRoleId}
                onChange={(settings) =>
                  handleChangeFormData({
                    settings,
                  })
                }
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={3} />
            <Grid item xs={9}>
              <button
                className="quiz-form__text-btn"
                onClick={() => setShowSettings(true)}
              >
                Показать настройки
              </button>
            </Grid>
          </>
        )}
        <Grid item xs={3}></Grid>
        <Grid item xs={9}>
          <FormControlLabel
            label={<Box sx={{ ml: 1 }}>отметить новость как важную</Box>}
            control={
              <StyledCheckbox
                name="isImportant"
                size="small"
                checked={!!formData.isImportant}
                onChange={({ target }) =>
                  handleChangeFormData({
                    isImportant: target.checked,
                  })
                }
              />
            }
          />
        </Grid>
        <Grid item mt={2}>
          Целевая аудитория:{' '}
          {isAllUsers
            ? UI_MESSAGE.TARGET_AUDIENCE_ALL
            : isLoadError
            ? '-'
            : userCount.toLocaleString('ru')}
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ mt: 4, p: '24px 32px', backgroundColor: '#f2f4f7' }}
        >
          <Grid container columnSpacing={2} sx={{ justifyContent: 'flex-end' }}>
            <Grid item xs="auto">
              <Button
                variant="fourth"
                sx={{
                  backgroundColor: '#fff',
                  p: 1.5,
                  minWidth: '50px',
                }}
                onClick={handlePreview}
              >
                <IconEye height={20} width={20} />
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button
                variant="fourth"
                disabled={disableSave}
                sx={{ backgroundColor: '#fff' }}
                onClick={handleSave}
              >
                Сохранить
              </Button>
            </Grid>
            <Grid item xs="auto">
              <Button
                variant="first"
                disabled={disabledPublishBtn}
                onClick={handlePublish}
              >
                Опубликовать{' '}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

async function mapToNews(
  data: FormData,
  status: NewsStatus,
  bodyContent: string,
) {
  let imgId: number | undefined = undefined;
  const fileList: FileDto[] = [];
  if (data.images) {
    const image = data?.images[0];

    const files = data.files?.reduce(
      (acc, file) => {
        if (isOfType<File>(file, 'name')) {
          acc.new.push(file);
        } else {
          acc.exists.push(file);
        }
        return acc;
      },
      { new: [], exists: [] } as { new: File[]; exists: FileDto[] },
    );
    const imageNotExists = image && isOfType<ReactFilesFile>(image, 'name');
    const totalUpload: (ReactFilesFile | File)[] = imageNotExists
      ? [image, ...files.new]
      : files.new;
    let uploadedFiles: FileDto[] = [];
    if (totalUpload.length > 0) {
      uploadedFiles = await newsAPI.addFiles(totalUpload);
    }
    const img = uploadedFiles && imageNotExists ? uploadedFiles[0] : image;

    if (img) {
      fileList.push(img as FileDto);
      imgId = img.id as number;
    }

    fileList.push(...files.exists);
    if (uploadedFiles) {
      const onlyFiles = [...uploadedFiles];
      if (imageNotExists) {
        onlyFiles.splice(0, 1);
      }
      if (onlyFiles.length) {
        fileList.push(...onlyFiles);
      }
    }
  }

  const newsItem: News = {
    status,
    startDate: dayjs(data?.settings?.period[0]).toISOString(),
    endDate: dayjs(data.settings.period[1]).toISOString(),
    body: bodyContent,
    title: data.title,
    isImportant: data.isImportant,
    eduLevels: data.settings.educationLevel.map((item) => ({ id: +item })),
    targetAudience: data.settings.audience.map((item) => ({ id: +item })),
    genders:
      data.settings.forWhom === 'all'
        ? ['FEMALE', 'MALE']
        : [data.settings.forWhom],
    parallels: data.settings.parallel ? [{ id: +data.settings.parallel }] : [],
    regions: data.settings.region.map(({ value, label }) => ({
      id: +value,
      name: label,
    })),
    files: fileList,
    img: imgId,
    eduOrganizations: data.settings.oo
      ? data.settings.oo.map((item) => +item)
      : [],
    eduGroupOrganizations: data.settings.ooGroup
      ? data.settings.ooGroup.map((item) => +item)
      : [],
  };

  return newsItem;
}

const mapNewsToFormData = (newsItem: News): FormData => {
  const {
    title,
    body,
    img: imgId,
    files,
    isImportant,
    targetAudience,
    eduLevels,
    parallels = [],
    startDate,
    endDate,
    regions,
    genders,
    eduOrganizations,
    eduGroupOrganizations,
  } = cloneDeep(newsItem);

  const mainImageIndex = files.findIndex((file) => file.id === imgId);

  const mainImage = ~mainImageIndex
    ? // @ts-ignore
      (files.splice(mainImageIndex, 1) as FileDto[] | undefined)
    : undefined;

  return {
    title,
    body,
    images: mainImage,
    isImportant,
    files: files as FileDto[],
    settings: {
      audience: targetAudience.map((item) => item.id),
      educationLevel: eduLevels.map((item) => item.id),
      parallel: parallels.map(({ id }) => id),
      period: [
        startDate ? new Date(startDate) : undefined,
        endDate ? new Date(endDate) : undefined,
      ],
      region: regions.map((item) => ({
        label: item.name || '-',
        value: item.id,
      })),
      oo: eduOrganizations.map((org) => org.toString()),
      ooGroup: eduGroupOrganizations.map((org) => org.toString()),
      forWhom:
        genders.length === 2
          ? 'all'
          : genders[0] === 'MALE'
          ? 'MALE'
          : 'FEMALE',
    },
  };
};
