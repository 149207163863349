import React from 'react';

const Headers = () => {
  return (
    <select className="ql-header" defaultValue="" onChange={(e) => e.persist()}>
      <option value="">Абзац</option>
      <option value="1">Заголовок 1</option>
      <option value="2">Заголовок 2</option>
      <option value="3">Заголовок 3</option>
      <option value="4">Заголовок 4</option>
      <option value="5">Заголовок 5</option>
      <option value="6">Заголовок 6</option>
    </select>
  );
};

export default Headers;
