import Quill from 'quill';

const Parchment = Quill.import('parchment');
const pixelLevels = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
const TAB_MULTIPLIER = 30;

export class IndentAttributor extends Parchment.StyleAttributor {
  add(node: HTMLElement, value: any) {
    return super.add(node, `${+value * TAB_MULTIPLIER}px`);
  }

  value(node: HTMLElement) {
    return parseFloat(super.value(node)) / TAB_MULTIPLIER || undefined;
  }
}

export const IndentStyle = new IndentAttributor('indent', 'margin-left', {
  scope: Parchment.Scope.BLOCK,
  whitelist: pixelLevels.map((value) => `${value * TAB_MULTIPLIER}px`),
});
