import React from 'react';
import { ReactComponent as UndoIcon } from 'quill/assets/icons/undo.svg';
import { ReactComponent as RedoIcon } from 'quill/assets/icons/redo.svg';

const History = () => {
  return (
    <>
      <button className="ql-undo">
        <UndoIcon />
      </button>
      <button className="ql-redo">
        <RedoIcon />
      </button>
    </>
  );
};

export default History;
