import React, { forwardRef, useMemo } from 'react';
import Quill from 'quill';
import Editor, { EditorProps } from './Editor';
import CustomToolbar, {
  CustomToolbarProps,
} from './components/toolbar/CustomToolbar';
import './Wysiwyg.scss';

type WysiwygProps = CustomToolbarProps &
  Pick<
    EditorProps,
    'value' | 'onChange' | 'defaultValue' | 'style' | 'formats'
  >;

const Wysiwyg = forwardRef<Quill, WysiwygProps>(
  ({ align, options, ...editorProps }: WysiwygProps, ref) => {
    const modules = useMemo(
      () => ({
        toolbar: {
          container: '#toolbar',
        },
      }),
      [],
    );

    return (
      <div className="wysiwyg__wrapper">
        <CustomToolbar options={options} align={align} />
        <Editor
          ref={ref}
          className="wysiwyg__editor"
          theme="snow"
          modules={modules}
          {...editorProps}
        />
      </div>
    );
  },
);

Wysiwyg.displayName = 'Wysiwyg';

export default Wysiwyg;
