import {
  ALLOWED_HTML_ATTRIBUTES,
  ALLOWED_HTML_ELEMENTS,
} from '@constants/config';
import * as xmldom from 'xmldom';

const getText = (items: NodeListOf<Node>): string[] => {
  let textItems: string[] = [];
  Array.from(items).forEach((item) => {
    if (item.nodeName === '#text' && item.nodeValue) {
      textItems.push(item.nodeValue);
    } else {
      if (ALLOWED_HTML_ELEMENTS.includes(item.nodeName)) {
        const textList = getText(item.childNodes);
        const attributes: string[] = [];
        Array.from((item as Element).attributes).forEach((attr) => {
          if (ALLOWED_HTML_ATTRIBUTES.includes(attr.name)) {
            attributes.push(`${attr.name}="${attr.value}"`);
          }
        });
        textItems.push(
          `<${item.nodeName} ${attributes.join(' ')}>${textList.join('')}</${
            item.nodeName
          }>`,
        );
      } else {
        if (item.childNodes) {
          const textList = getText(item.childNodes);

          if (textList.length) {
            textItems = [...textItems, textList.join('')];
          }
        }
      }
    }
  });
  return textItems;
};

export const getPlainTextWithLinks = (content: string) => {
  const domDoc = new xmldom.DOMParser().parseFromString(content, 'text/xml');
  const textList: string[] = getText(domDoc.childNodes);
  return textList.join('</br>');
};
