import { FC, useCallback, useLayoutEffect, useMemo, useState } from 'react';
import './Profile.scss';
import { Avatar, Box, Grid, Modal, Typography } from '@mui/material';
import { ReactComponent as ConfirmIcon } from '@assets/icons/confirm.svg';
import { ReactComponent as CaretDownIcon } from '@assets/icons/ico-caret-down.svg';
import { useSelector } from 'react-redux';
import {
  getUserProfileList,
  getUserInfo,
  getUserRole,
  getIsAuth,
  getUser,
  getUserRoleLabel,
  getUserPupilInfo,
} from '@redux/user';
import { EUserRole, UserHistory } from '@redux/user/types';
import { UI_MESSAGE } from '@declarations/enum/uiMessage';
import ProfileTeacherRoleList from '@components/Profile/ProfileTeacherRoleList/ProfileTeacherRoleList';
import { RequestStatuses } from '@redux/ui/types';
import ProfileParentRoleList from '@components/Profile/ProfileParentRoleList/ProfileParentRoleList';
import Button from '@components/common/Button/Button';
import { ReactComponent as OkIcon } from '@assets/icons/ok-fill.svg';
import { AuthAccount, NextOfKinProfile } from '@declarations/profile';
import { ProfileCategory, UserProfileStatus } from '@declarations/enum/profile';
import { userApi } from '@api/user';
import { EntityTypes } from '@mock-data/entities';
import EntryHistory from './EntryHistory/EntryHistory';
import ProfileStudentInfo from '@components/Profile/ProfileStudentInfo/ProfileStudentInfo';
import ProfileStudentChangeInfo from '@components/Profile/ProfileStudentChangeInfo/ProfileStudentChangeInfo';
import { Definitions } from '@declarations/common';
import ProfileAdminRoleList from '@components/Profile/ProfileAdminRoleList/ProfileAdminRoleList';
import {
  COOKIE_NAMES,
  MAIN_DOMAIN,
  MY_FILES_LINK,
  MY_LIBRARY_LINK,
  TESTS_LINK,
} from '@constants/config';
import { ValueField } from './ValueField';
import classNames from 'classnames';
import { deleteCookie } from '@lib/cookie';
import { loginLink } from '@lib/auth/getAuthLinks';

interface ProfileProps {
  userRoleList: any;
  fetchUserRole: () => void;
  userHistory: UserHistory[];
  userRoleFetchingStatus?: RequestStatuses;
  authAccount?: AuthAccount;
}

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 480,
  bgcolor: '#FFFFFF',
  borderRadius: '8px',
  boxShadow:
    '0px 1px 8px rgba(123, 135, 158, 0.12), 0px 8px 32px rgba(123, 135, 158, 0.25)',
  p: 4,
};

export const ProfilePage: FC<ProfileProps> = ({
  userRoleList,
  fetchUserRole,
  authAccount,
  userHistory,
  // userRoleFetchingStatus,
}) => {
  const userObj = useSelector(getUser);
  const userInfo = useSelector(getUserInfo);
  const userProfiles = useSelector(getUserProfileList);
  const userRoleLabel = useSelector(getUserRoleLabel);
  const userRole = useSelector(getUserRole);
  const isAuth = useSelector(getIsAuth);
  const pupilInfo = useSelector(getUserPupilInfo);

  const [open, setOpen] = useState(false);
  const [isMenuOpened, setIsMenuOpened] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [
    showIndicatorAboutUnconfirmedRole,
    setShowIndicatorAboutUnconfirmedRole,
  ] = useState<boolean>(false);

  const canAddProfile = useMemo(() => {
    const hashTable = userProfiles?.reduce((acc, profile) => {
      acc[profile.code] = profile;
      return acc;
    }, {} as { [code: string]: Definitions.Coding });

    return !hashTable[ProfileCategory.LEGAL_REPRESENTATIVE];
  }, [userProfiles]);

  useLayoutEffect(() => {
    if (!userRoleList || !userObj) {
      fetchUserRole();
    }
  }, [fetchUserRole, userObj, userRoleList]);

  const userMobile = userRoleList?.userMobile;
  const userBirthday = userRoleList?.userBirthday;

  const handleAddNextOfKinProfile = useCallback(async () => {
    if (authAccount) {
      const profile: NextOfKinProfile = {
        resourceType: EntityTypes.NEXT_OF_KIN_PROFILE,
        roleCategory: '4',
        status: UserProfileStatus.CREATED_BY_SELF,
        authAccount: {
          reference: `${authAccount.resourceType}/${authAccount.id}`,
        },
      };
      try {
        const response = await userApi.addNextOfKinProfile(profile);
        if (response) {
          handleClose();
          deleteCookie(COOKIE_NAMES.ACCESS_TOKEN, MAIN_DOMAIN);
          deleteCookie(COOKIE_NAMES.REFRESH_TOKEN, MAIN_DOMAIN);
          window.location.href = loginLink;
        }
      } catch (err) {
        console.error(err);
      }
    }
  }, [authAccount]);

  return (
    <div className="profile">
      <Grid
        container
        rowSpacing={2}
        columnSpacing={{
          xs: 0,
          lg: 2,
        }}
      >
        <Grid
          item
          xs={12}
          lg={3}
          flexDirection={{
            xs: 'row',
            lg: 'column',
          }}
          id="profile-menu-list"
        >
          <Box
            className={classNames({
              'profile-menu-list': true,
              'profile-menu-list--opened': isMenuOpened,
            })}
          >
            <Box
              className="profile-menu-list__arrow"
              onClick={() => setIsMenuOpened((prev) => !prev)}
            >
              <CaretDownIcon />
            </Box>
            <Box className="profile-menu-list__item profile-menu-list__item--active">
              Личная информация
            </Box>
            <Box className="profile-menu-list__item">
              <a href={MY_LIBRARY_LINK}>Моя библиотека</a>
            </Box>
            <Box className="profile-menu-list__item">
              <a href={MY_FILES_LINK}>Мои файлы</a>
            </Box>
            <Box className="profile-menu-list__item">
              <a href={TESTS_LINK}>Тесты</a>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} lg={9}>
          <div className="profile__section profile__section--main">
            <Grid container spacing={2}>
              <Grid item xs>
                <h1 className="profile__title">{userInfo.fullName}</h1>
                <Grid container spacing={1} className="profile__info">
                  <Grid item>
                    <ValueField label="Профиль">
                      <Grid container flexDirection="column">
                        <Grid item>
                          <div className="profile__value">
                            {userRoleLabel}
                            <ConfirmIcon
                              style={{
                                marginLeft: '6px',
                              }}
                            />
                          </div>
                          {showIndicatorAboutUnconfirmedRole && (
                            <Box sx={{ mt: 1 }}>
                              <div className="profile__notification">
                                {UI_MESSAGE.YOU_HAVE_UNCONFIRMED_ROLES}
                              </div>
                            </Box>
                          )}
                        </Grid>
                        <Grid item>
                          {canAddProfile && (
                            <Button
                              onClick={handleOpen}
                              sx={{
                                color: '#006DE5',
                                p: 0,
                                '&:hover': {
                                  backgroundColor: 'transparent',
                                },
                              }}
                            >
                              Добавить профиль
                            </Button>
                          )}
                          <Modal
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                          >
                            <Box sx={style}>
                              <Typography
                                id="modal-modal-title"
                                variant="h6"
                                component="h2"
                              >
                                Добавление профиля
                              </Typography>
                              <Box
                                id="modal-modal-description"
                                sx={{
                                  mt: 2,
                                  border: '1px solid #CAD3E0',
                                  boxShadow: '0px 1px 0px #E8E8ED',
                                  borderRadius: '8px',
                                  p: 3,
                                }}
                              >
                                <Grid
                                  container
                                  sx={{
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={8}
                                    container
                                    flexDirection="column"
                                    rowSpacing={1}
                                  >
                                    <Grid item>
                                      <Typography variant="h5">
                                        Законный представитель
                                      </Typography>
                                    </Grid>
                                    <Grid item>
                                      <Typography
                                        variant="caption"
                                        sx={{ color: '#626B7A' }}
                                      >
                                        Родитель, попечитель или опекун
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  <Grid item sx={{ color: '#075FF7' }}>
                                    <OkIcon
                                      style={{
                                        width: 40,
                                        height: 40,
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                              </Box>
                              <Grid
                                container
                                columnSpacing={2}
                                sx={{ mt: 4, justifyContent: 'flex-end' }}
                              >
                                <Grid item>
                                  <Button
                                    variant="fourth"
                                    onClick={handleClose}
                                  >
                                    Отмена
                                  </Button>
                                </Grid>
                                <Grid item>
                                  <Button
                                    variant="first"
                                    onClick={handleAddNextOfKinProfile}
                                  >
                                    Добавить
                                  </Button>
                                </Grid>
                              </Grid>
                            </Box>
                          </Modal>
                        </Grid>
                      </Grid>
                    </ValueField>
                  </Grid>
                </Grid>
                {isAuth && userRole === EUserRole.STUDENT && (
                  <ProfileStudentInfo userRoleList={userRoleList} />
                )}
                <Grid container spacing={1} className="profile__info">
                  <Grid item xs={3} sm={2.3}>
                    <div className="profile__label">Эл. почта</div>
                  </Grid>
                  <Grid item xs={10} sm={10} md>
                    <div className="profile__value">{userInfo.email}</div>
                  </Grid>
                </Grid>
                <Grid container spacing={1} className="profile__info">
                  <Grid item xs={3} sm={2.3}>
                    <div className="profile__label">Телефон</div>
                  </Grid>
                  <Grid item xs={10} sm={10} md>
                    <div className="profile__value">{userMobile}</div>
                  </Grid>
                </Grid>
                <Grid container spacing={1} className="profile__info">
                  <Grid item xs={3} sm={2.3}>
                    <div className="profile__label">СНИЛС</div>
                  </Grid>
                  <Grid item xs={10} sm={10} md>
                    <div className="profile__value">
                      {userInfo.preferred_username}
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs="auto">
                <Avatar
                  sx={{
                    bgcolor: '#F2F4F7',
                    color: '#2C3038',
                    fontSize: 24,
                    fontWeight: 500,
                    width: {
                      xs: '66px',
                      lg: '120px',
                    },
                    height: {
                      xs: '66px',
                      lg: '120px',
                    },
                  }}
                  variant="circular"
                >
                  {userInfo.initials}
                </Avatar>
              </Grid>
            </Grid>
          </div>
          <div className="profile__section">
            {isAuth && userRole === EUserRole.TEACHER && (
              <ProfileTeacherRoleList
                roleList={userRoleList}
                onReloadRoleList={fetchUserRole}
              />
            )}
            {isAuth && userRole === EUserRole.PARENT && (
              <ProfileParentRoleList
                pupilInfo={pupilInfo}
                onReloadRoleList={fetchUserRole}
                onChangeIndicatorStatusAboutUnconfirmedRole={(
                  status: boolean,
                ) => setShowIndicatorAboutUnconfirmedRole(status)}
              />
            )}
            {isAuth &&
              [EUserRole.ADMIN, EUserRole.PARTICIPANT].includes(userRole) && (
                <ProfileAdminRoleList userRoleList={userRoleList} />
              )}
            {isAuth && userRole === EUserRole.STUDENT && (
              <ProfileStudentChangeInfo
                userBirthday={userBirthday}
                userMail={userInfo?.email}
                authAccount={authAccount}
                onReloadRoleList={fetchUserRole}
              />
            )}
          </div>
          <EntryHistory history={userHistory} />
        </Grid>
      </Grid>
    </div>
  );
};
