import React, { Fragment } from 'react';
import Align, { AlignProps } from './Align';
import History from './History';
import Headers from './Headers';
import './CustomToolbar.scss';

enum OptionsEnum {
  align = 'align',
  headers = 'headers',
  history = 'history',
  bold = 'bold',
  italic = 'italic',
  background = 'background',
  list = 'list',
  indent = 'indent',
  link = 'link',
  image = 'image',
  video = 'video',
  clean = 'clean',
}

type Options = keyof typeof OptionsEnum;

export type CustomToolbarProps = Partial<{
  options: Options[][];
  align: AlignProps;
}>;

const DEFAULT_OPTIONS: Options[][] = [
  ['history'],
  ['headers'],
  ['bold', 'italic', 'background'],
  ['align'],
  ['list', 'indent'],
  ['link', 'image', 'video'],
  ['clean'],
];

const CustomToolbar = ({
  options = DEFAULT_OPTIONS,
  align,
}: CustomToolbarProps) => {
  const getElement = (options: Options[]) => {
    return options.map((option) => {
      switch (option) {
        case OptionsEnum.history:
          return <History key={option} />;
        case OptionsEnum.headers:
          return <Headers key={option} />;
        case OptionsEnum.bold:
          return <button key={option} className="ql-bold" />;
        case OptionsEnum.italic:
          return <button key={option} className="ql-italic" />;
        case OptionsEnum.background:
          return <select key={option} className="ql-background" />;
        case OptionsEnum.align:
          return <Align key={option} {...align} />;
        case OptionsEnum.list:
          return (
            <Fragment key={option}>
              <button className="ql-list" value="bullet" />
              <button className="ql-list" value="ordered" />
            </Fragment>
          );
        case OptionsEnum.indent:
          return (
            <Fragment key={option}>
              <button className="ql-indent" value="-1" />
              <button className="ql-indent" value="+1" />
            </Fragment>
          );
        case OptionsEnum.link:
          return <button key={option} className="ql-link" />;
        case OptionsEnum.image:
          return <button key={option} className="ql-image" />;
        case OptionsEnum.video:
          return <button key={option} className="ql-video" />;
        case OptionsEnum.clean:
          return <button key={option} className="ql-clean" />;
        default:
          return;
      }
    });
  };

  const getToolbar = () => {
    return options?.map((item, index) => {
      return (
        <span key={`group_${index}`} className="ql-formats">
          {getElement(item)}
        </span>
      );
    });
  };

  return (
    <div id="toolbar" className="wysiwyg__toolbar">
      {getToolbar()}
    </div>
  );
};

export default CustomToolbar;
