import React from 'react';

export type AlignProps = {
  isDropDown?: boolean;
};

const Align = ({ isDropDown }: AlignProps) => {
  return isDropDown ? (
    <select className="ql-align" />
  ) : (
    <>
      <button className="ql-align" value="" />
      <button className="ql-align" value="center" />
      <button className="ql-align" value="right" />
      <button className="ql-align" value="justify" />
    </>
  );
};

export default Align;
