import { FC, useEffect, useMemo, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import { ReactComponent as WarningIcon } from '@assets/icons/alert/warning.svg';
import { ResponsiveContainer } from '@components/common/ResponsiveContainer/ResponsiveContainer';
import { alertApi, AlertMessage } from '@api/alert';

const Alert: FC = () => {
  const [alertList, setAlertList] = useState<AlertMessage[]>();

  useEffect(() => {
    const loadMessages = async () => {
      const message = await alertApi.getActualMessages();
      if (message) {
        setAlertList(message);
      }
    };
    loadMessages();
  }, []);

  const actualAlertMessage = useMemo(() => {
    return alertList?.length ? alertList[alertList.length - 1] : null;
  }, [alertList]);

  if (!alertList || !alertList.length) {
    return null;
  }

  return (
    <ResponsiveContainer>
      <Grid
        container
        sx={{
          backgroundColor: '#FFF0ED',
          mt: 2,
          borderRadius: '8px',
        }}
      >
        <Grid
          item
          container
          columnSpacing={2}
          sx={{
            alignItems: 'center',
            p: 3,
          }}
        >
          <Grid
            item
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexShrink: '0',
            }}
          >
            <WarningIcon />
          </Grid>
          <Grid item xs>
            <Typography variant={'additionally' as 'h1'}>
              <span
                dangerouslySetInnerHTML={{
                  __html: actualAlertMessage?.content || '',
                }}
              />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </ResponsiveContainer>
  );
};

export default Alert;
