import { renderToStaticMarkup } from 'react-dom/server';
import Quill from 'quill';
// @ts-ignore
import { BaseModule } from 'quill-image-resize-module-react/src/modules/BaseModule';
import { ReactComponent as IconAlignLeft } from 'quill/assets/icons/align-left.svg';
import { ReactComponent as IconAlignCenter } from 'quill/assets/icons/align-center.svg';
import { ReactComponent as IconAlignRight } from 'quill/assets/icons/align-right.svg';

type ConstructorType = {
  overlay: HTMLDivElement;
  options: Record<string, any>;
  img: HTMLImageElement;
  onUpdate: VoidFunction;
};

type ButtonType = {
  icon: string;
  apply: VoidFunction;
  isApplied: () => boolean;
};

const Parchment = Quill.import('parchment');
const FloatStyle = new Parchment.StyleAttributor('float', 'float');
const MarginStyle = new Parchment.StyleAttributor('margin', 'margin');
const DisplayStyle = new Parchment.StyleAttributor('display', 'display');

// Класс скопирован из quill-image-resize-module-react/src/modules/Toolbar и доработан,
// т.к. функционал выравнивания изображения не работал с версией Parchment из Quill v2.0.2

export class ImageToolbar extends BaseModule {
  toolbar: HTMLDivElement | undefined;
  overlay: HTMLDivElement;
  options: Record<string, any> = {};
  alignments: ButtonType[] = [];
  img: HTMLImageElement;
  requestUpdate: VoidFunction;

  constructor(resizer: ConstructorType) {
    super(resizer);
    this.overlay = resizer.overlay;
    this.img = resizer.img;
    this.options = resizer.options;
    this.requestUpdate = resizer.onUpdate;
  }

  onCreate = async () => {
    // Setup Toolbar
    this.toolbar = document.createElement('div');
    Object.assign(this.toolbar.style, this.options.toolbarStyles);
    this.overlay.appendChild(this.toolbar);

    // Setup Buttons
    this._defineAlignments();
    this._addToolbarButtons();
  };

  _defineAlignments = () => {
    this.alignments = [
      {
        icon: renderToStaticMarkup(<IconAlignLeft />),
        apply: () => {
          DisplayStyle.add(this.img, 'inline');
          FloatStyle.add(this.img, 'left');
          MarginStyle.add(this.img, '0 1em 1em 0');
        },
        isApplied: () => FloatStyle.value(this.img) == 'left',
      },
      {
        icon: renderToStaticMarkup(<IconAlignCenter />),
        apply: () => {
          DisplayStyle.add(this.img, 'block');
          FloatStyle.remove(this.img);
          MarginStyle.add(this.img, 'auto');
        },
        isApplied: () => MarginStyle.value(this.img) == 'auto',
      },
      {
        icon: renderToStaticMarkup(<IconAlignRight />),
        apply: () => {
          DisplayStyle.add(this.img, 'inline');
          FloatStyle.add(this.img, 'right');
          MarginStyle.add(this.img, '0 0 1em 1em');
        },
        isApplied: () => FloatStyle.value(this.img) == 'right',
      },
    ];
  };

  _addToolbarButtons = () => {
    const buttons: HTMLSpanElement[] = [];
    this.alignments.forEach(async (alignment, idx) => {
      const button = document.createElement('span');
      buttons.push(button);
      button.innerHTML = alignment.icon;
      button.addEventListener('click', () => {
        // deselect all buttons
        buttons.forEach((button) => (button.style.filter = ''));
        if (alignment.isApplied()) {
          // If applied, unapply
          FloatStyle.remove(this.img);
          MarginStyle.remove(this.img);
          DisplayStyle.remove(this.img);
        } else {
          // otherwise, select button and apply
          this._selectButton(button);
          alignment.apply();
        }
        // image may change position; redraw drag handles
        this.requestUpdate();
      });
      Object.assign(button.style, this.options.toolbarButtonStyles);
      if (idx > 0) {
        button.style.borderLeftWidth = '0';
      }
      Object.assign(
        (button.children[0] as HTMLElement).style,
        this.options.toolbarButtonSvgStyles,
      );
      if (alignment.isApplied()) {
        // select button if previously applied
        this._selectButton(button);
      }
      this.toolbar?.appendChild(button);
    });
  };

  _selectButton = (button: HTMLSpanElement) => {
    button.style.filter = 'invert(20%)';
  };
}
