import React, {
  CSSProperties,
  forwardRef,
  useEffect,
  useLayoutEffect,
  useRef,
} from 'react';
import Quill, { Parchment, QuillOptions } from 'quill';
// @ts-ignore
import ImageResize from 'quill-image-resize-module-react';
import { ImageToolbar } from './components/image-toolbar/ImageToolbar';
import { IndentStyle } from './components/indent-style/IndentStyle';
import 'quill/dist/quill.snow.css';

export type EditorProps = Partial<
  QuillOptions & {
    defaultValue: string;
    value: string;
    className: string;
    style: CSSProperties;
    onChange: (html: string, text: string, quill: Quill) => void;
  }
>;

const FORMATS = [
  'header',
  'align',
  'bold',
  'italic',
  'list',
  'indent',
  'link',
  'image',
  'background',
  'video',
];

// using styles instead of classes
const AlignStyle = Quill.import(
  'attributors/style/align',
) as Parchment.StyleAttributor;
Quill.register(AlignStyle, true);

Quill.register(IndentStyle, true);

// adding ImageResize module
Quill.register('modules/imageResize', ImageResize);

const Editor = forwardRef<Quill, EditorProps>(
  (
    {
      readOnly,
      value,
      defaultValue,
      onChange,
      className,
      style,
      ...rest
    }: EditorProps,
    ref,
  ) => {
    const quillRef = useRef<Quill | null>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const defaultValueRef = useRef(defaultValue);
    const onChangeRef = useRef(onChange);

    useLayoutEffect(() => {
      onChangeRef.current = onChange;
      defaultValueRef.current = defaultValue;
    });

    useEffect(() => {
      if (typeof ref === 'object' && ref) {
        ref.current?.enable(!readOnly);
      }
    }, [ref, readOnly]);

    const handleAddImage = function () {
      // @ts-ignore
      const quill = this.quill;
      const rangeIndex = quill.getSelection()?.index || 0;

      // @ts-ignore
      const tooltip = quill.theme.tooltip;
      const originalSave = tooltip.save;

      tooltip.save = () => {
        const value = tooltip.textbox.value;
        if (value) {
          quill.insertEmbed(rangeIndex, 'image', value, 'user');
        }
        tooltip.save = originalSave;
        tooltip.textbox.value = '';
        tooltip.hide();
      };

      tooltip.edit('image');
      tooltip.textbox.placeholder = 'Введите URL';
    };

    const handleUndo = function () {
      // @ts-ignore
      return this.quill.history.undo();
    };

    const handleRedo = function () {
      // @ts-ignore
      return this.quill.history.redo();
    };

    useEffect(() => {
      const container = containerRef.current;
      const editorContainer = container?.appendChild(
        container.ownerDocument.createElement('div'),
      );

      if (editorContainer) {
        const quill = new Quill(editorContainer, {
          theme: 'snow',
          ...rest,
          readOnly: readOnly,
          modules: {
            ...rest.modules,
            imageResize: {
              modules: ['DisplaySize', ImageToolbar, 'Resize'],
            },
            toolbar: {
              ...(rest.modules?.toolbar as Record<string, any>),
              handlers: {
                undo: handleUndo,
                redo: handleRedo,
                image: handleAddImage,
                ...((rest.modules?.toolbar as Record<string, any>)
                  ?.handlers as Record<string, any>),
              },
            },
          },
          formats: rest.formats || FORMATS,
          bounds: editorContainer,
        });

        if (defaultValueRef.current) {
          quill.setContents(
            quill.clipboard.convert({
              html: defaultValueRef.current,
            }),
          );
        }

        if (onChangeRef.current) {
          quill.on(Quill.events.TEXT_CHANGE, () => {
            onChangeRef.current?.(quill.root.innerHTML, quill.getText(), quill);
          });
          onChangeRef.current(quill.root.innerHTML, quill.getText(), quill);
        }

        quillRef.current = quill;
      }

      return () => {
        quillRef.current = null;
        if (container) {
          container.innerHTML = '';
        }
      };

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref]);

    useEffect(() => {
      const node = quillRef.current;

      if (typeof ref === 'function') {
        ref(node);
      } else if (ref) {
        ref.current = node;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ref, quillRef.current]);

    useEffect(() => {
      if (
        quillRef.current &&
        value !== undefined &&
        quillRef.current.root.innerHTML !== value
      ) {
        const range = quillRef.current.getSelection();
        quillRef.current.setContents(
          quillRef.current.clipboard.convert({ html: value }),
        );

        if (range) {
          const length = quillRef.current.getLength();
          range.index = Math.max(0, Math.min(range.index, length - 1));
          range.length = Math.max(
            0,
            Math.min(range.length, length - 1 - range.index),
          );
          quillRef.current.setSelection(range);
        }
      }
    }, [value]);

    return <div ref={containerRef} className={className} style={style} />;
  },
);

Editor.displayName = 'Editor';

export default Editor;
